import React, { useState } from 'react'
import Layout from '../components/Layout'
import Apprentice from '../components/Apprentice'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import '../styles/styles.scss'
import Arrow from '../images/icons/down-chevron.svg'

const annThropeSeries = () => {
  const image = useStaticQuery(graphql`
    query {
      apprentice: file(relativePath: { eq: "apprenticebook.jpg" }) {
        childImageSharp {
          fixed(width: 270) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `)
  // const [clickState, setClickState] = useState(false)
  // const [clickStateTwo, setClickStateTwo] = useState(false)

  return (
    <Layout>
      <Apprentice />
      <div className="book-container">
        <div className="books-book">
          <div className="books">
            <Img fixed={image.apprentice.childImageSharp.fixed} />
          </div>
        </div>
        <div className="synopsis">
          <div className="text-synopsis">
            <p>
              He could have been anything when he grew up. Somehow “killer”
              ended up top of his list…
            </p>
            <p>
              Young orphan Billy Brambling doesn’t believe in being ordinary.
              Fearing life already passing him by, the eccentric nine-year-old
              jumps at the chance to learn how to become a deadly assassin. And
              with his future assured in the lethal hands of infamous hitwoman
              Ann Thrope, he eagerly begins his career in murder.
            </p>
            <p>
              Studying under the sophisticated senior, a blind swordmaster, and
              an eight-inch fairy, Billy focuses on being the most ruthless
              gun-for-hire across galaxies. But when a dangerous assignment
              compromises his safety, the youngster must make a life-threatening
              choice between friend and foe.
            </p>
            <p>
              Can the boy executioner survive a brutal alien mission, or will he
              come to an explosive end?
            </p>
          </div>

          <div className="button-wrap-around">
            <div className="button-wrap">
              <a href="https://geni.us/wEDrAJ" target="_blank" rel="noreferrer">
                EBOOK
              </a>
              <a href="https://geni.us/yQTdbj" target="_blank" rel="noreferrer">
                PAPERBACK
              </a>
            </div>
          </div>

          {/* <div className="button-wrap-around">
            <div className="button-wrap">
              <button
                onClick={() => {
                  setClickState(!clickState)
                }}
              >
                EBOOK
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickState ? 'rotate(-180deg)' : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
              <button
                onClick={() => {
                  setClickStateTwo(!clickStateTwo)
                }}
              >
                PAPERBACK
                <img
                  style={{
                    width: '20px',
                    marginLeft: '10px',
                    transform: clickStateTwo
                      ? 'rotate(-180deg)'
                      : 'rotate(-0deg)',
                    transition: '200ms',
                  }}
                  src={Arrow}
                  alt="arrow icon indicating, that if the button is pressed it will reveal more buttons"
                />
              </button>
            </div>
            {clickState ? (
              <div className="button-wrap-amazon">
                <a
                  href="https://www.amazon.com/dp/B08DV79NVR/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596040586&sr=8-1&linkCode=ll1&tag=richardweale-20&linkId=1a4477b3d8fa1c76b97c1b3e403dc6c6"
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/dp/B08DV79NVR/ref=as_li_ss_tl?dchild=1&keywords=richard+weale&qid=1596041807&sr=8-1&linkCode=ll1&tag=richardweale-21&linkId=04dcda7182d6c6d5a803d98af97cd8a2&language=en_US"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
            {clickStateTwo ? (
              <div className="button-wrap-amazon-paper">
                <a
                  href="https://www.amazon.com/Apprentice-Ann-Thrope-Book-2/dp/B08QBMJK1Z/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=&sr="
                  target="_blank"
                >
                  AMAZON.COM
                </a>
                <a
                  href="https://www.amazon.co.uk/Apprentice-Ann-Thrope-Book-2/dp/B08QBMJK1Z/ref=tmm_pap_swatch_0?_encoding=UTF8&qid=1621507609&sr=8-1"
                  target="_blank"
                >
                  AMAZON.CO.UK
                </a>
              </div>
            ) : null}
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export default annThropeSeries
